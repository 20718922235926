export default [
  {
    path: '/user-center/clock-in-audit/list',
    name: 'ClockInAuditList',
    component: () => import('@/views/userCenter/clockInAudit/list/index'),
    meta: {
      title: '打卡审核',
    },
  },
  {
    path: '/user-center/clock-in-audit/detail',
    name: 'ClockInAuditDetail',
    component: () => import('@/views/userCenter/clockInAudit/detail/index'),
    meta: {
      title: '打卡审核详情',
    },
  },
]
